.App {
  font-family: "Manrope", sans-serif;
  background-color: #2B3A55;
  height: 100%;
  width: 100%;
}

.navbar-about {
  text-decoration: none;
  color: #F2E5E5;
}

.navbar-about:hover {
  color: #CE7777;
}

.navbar-medium {
  text-decoration: none;
  color: #F2E5E5;
}

.navbar-medium:hover {
  color: #CE7777;
}

.navbar-linkedin {
  text-decoration: none;
  color: #F2E5E5;
}

.navbar-linkedin:hover {
  color: #CE7777;
}

.navbar-github {
  text-decoration: none;
  color: #F2E5E5;
}

.navbar-github:hover {
  color: #CE7777;
}

.navbar-resume {
  text-decoration: none;
  color: #F2E5E5;
}

.navbar-resume:hover {
  color: #CE7777;
}